import React, { Component } from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";

// import Category from "../components/Products/Pages/CategoryPage";
import SubCategory from "../components/Products/Pages/SubCategoryPage";
import PopularProducts from "../components/Products/Components/PopularProducts";

class Page extends Component {
  render() {
    // const isCategory = !this.props.data.wpProductCategory.wpParent;
    // const isSubcategory = !!this.props.data.wpProductCategory.wpParent;

    const subcategories = this.props.data.wpProductCategory.wpChildren.nodes;
    const allProducts = this.props.data.allWpProduct.nodes;

    subcategories.map((subcategory) => {
      const id = subcategory.id;
      const products = allProducts.filter((product) =>
        product.productCategories.nodes
          .map((category) => category.id)
          .includes(id)
      );
      subcategory.products = products;

      return subcategory;
    });

    const products = allProducts.filter((product) =>
      product.productCategories.nodes
        .map((category) => category.id)
        .includes(this.props.data.wpProductCategory.id)
    );

    return (
      <Layout
        meta={{
          ...this.props.data.wpProductCategory.Meta,
          canonical: this.props.data.wpProductCategory.uri,
        }}
        path={this.props.pageContext.pagePath}
        cta={this.props.data.wpProductCategory.PageCTA}
        themeOptions={this.props.data.wp.themeOptions}
      >
        {/* {isCategory && (
          <Category
            {...this.props.data.wpProductCategory}
            subcategories={subcategories}
            products={products}
          />
        )}
        {isSubcategory && ( */}
        <SubCategory
          {...this.props.data.wpProductCategory}
          subcategories={subcategories}
          products={products}
        />
        {/* )} */}
        <PopularProducts
          products={
            this.props.data.wp.themeOptions.ThemeOptions.popularProducts
          }
          limit={4}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query($id: String) {
    allWpProduct(
      filter: {
        status: { eq: "publish" }
        productCategories: { nodes: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      nodes {
        id
        slug
        title
        uri
        link
        featuredImage {
          node {
            sourceUrl
          }
        }
        product {
          code
          description
          introduction
          overview
          order
          shortDescription
          productGallery {
            id
            localFile {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1660) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          variations {
            variantCode
            variantName
          }
        }
        brands {
          nodes {
            BrandExtras {
              logo {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
        productCategories {
          nodes {
            name
            id
          }
        }
      }
    }
    wpProductCategory(id: { eq: $id }) {
      id
      name
      description
      slug
      uri
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        ogImage {
          uri
        }
        twitterImage {
          uri
        }
      }
      wpChildren {
        nodes {
          id
          name
          uri
          productCategory {
            categoryImage {
              id
              link
              sourceUrl
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 80, maxWidth: 1660) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          products {
            nodes {
              id
            }
          }
        }
      }
      wpParent {
        node {
          id
          name
          slug
          uri
          wpChildren {
            nodes {
              id
              name
              uri
              productCategory {
                categoryImage {
                  id
                  link
                  sourceUrl
                  localFile {
                    publicURL
                    childImageSharp {
                      fluid(quality: 80, maxWidth: 1660) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              products {
                nodes {
                  id
                }
              }
              wpChildren {
                nodes {
                  id
                  name
                  uri
                  productCategory {
                    categoryImage {
                      id
                      link
                      sourceUrl
                      localFile {
                        publicURL
                        childImageSharp {
                          fluid(quality: 80, maxWidth: 1660) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                  products {
                    nodes {
                      id
                    }
                  }
                }
              }
            }
          }
          wpParent {
            node {
              id
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
          popularProducts {
            ... on WpProduct {
              id
              title
              uri
              uri
              link
              featuredImage {
                node {
                  sourceUrl
                }
              }
              product {
                code
                description
                fieldGroupName
                fixDescription
                fixGuide
                fixVideo
                hasHowtoGuide
                installDescription
                installGuide
                installVideo
                introduction
                overview
                shortDescription
                productGallery {
                  id
                  localFile {
                    childImageSharp {
                      fluid(quality: 80, maxWidth: 1660) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              brands {
                nodes {
                  BrandExtras {
                    logo {
                      localFile {
                        childImageSharp {
                          fluid(quality: 80, maxWidth: 1660) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;
